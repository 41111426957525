import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from 'component/atoms/PageHeader';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from 'container/HelpPage/List/Pagination';

import time from 'assets/images/mycurriculum_new/icon_time.png';
import play from 'assets/images/mycurriculum_new/icon_play.png';
import check from 'assets/images/mycurriculum_new/icon_check.png';
import more from 'assets/images/mycurriculum_new/arrow2.png';
import complete from 'assets/images/mycurriculum_new/icon_complete.png';
import ready from 'assets/images/mycurriculum_new/icon_ready.png';
import start from 'assets/images/mycurriculum_new/icon_start.png';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import contentsApi from 'api/contents';
import { range } from 'lodash';


const LearningHistoryDetail = ({ data }) => {
  const { t } = useTranslation('helpPage');
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setPage] = useState(1);
  const [currentPageNumbers, setPageNumbers] = useState([]);

  const [learningHours, setLearningHours] = useState(0);
  const [learningCourses, setLearningCourses] = useState(0);
  const [learningVideos, setLearningVideos] = useState(0);

  const token = useSelector((state) => state.user.token);

  const [{ data: learningHistoryData }, fetchLearningHistory] = useAxios(
    contentsApi.getHistoryList,
    [],
    true
  );

  const [{ data: learningHistoryWithPaging }, fetchLearningHistoryWithPaging] = useAxios(
    contentsApi.getWatchedList,
    [],
    true
  )

  const [isMobile, setisMobile] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };


  const createPages = () => {
    if (maxPage <= 5) {
      setPageNumbers(range(1, maxPage + 1, 1));
      return;
    }

    let start = currentPage;
    let end = currentPage;

    while (end - start < 4) {
      if (start > 1) start--;
      if (end < maxPage) end++;
    }

    setPageNumbers(range(start, end + 1, 1))
  };

  useEffect(() => {
    createPages();
  }, [maxPage]);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  useEffect(() => {
    fetchLearningHistory(token);
  }, [fetchLearningHistory]);

  useEffect(() => {
    if (currentPage) {
      fetchLearningHistoryWithPaging({ page: currentPage - 1, token: token });
      createPages();
    }
  }, [currentPage])

  useEffect(() => {
    if (learningHistoryData) {
      let courseCountSet = new Set;
      let sumOfTime = 0;

      const list = learningHistoryData.filter((item) => item.completed === true);
      list.forEach((item) => {
        courseCountSet.add(item.productId);
        sumOfTime += item.minute;
      });

      setLearningCourses(courseCountSet.size);
      setLearningVideos(list.length);
      setLearningHours(sumOfTime);

      // setFilteredList(list);
    }
  }, [learningHistoryData]);

  useEffect(()=>{
    if(learningHistoryWithPaging){
      setMaxPage(learningHistoryWithPaging.totalPages);
    }
  },[learningHistoryWithPaging])

  return (
    <LHDetailWrap>
      <PageHeader title={"Learning History"} />
      <LHWrap>
        <LHInfos>
          <LHInfo>
            <Icon><img src={check} /></Icon>
            {isMobile ? <div className='m_text'>Completed Courses</div> : <></>}
            <Text>
              <p>Completed Courses</p>
              <h3>{learningCourses}</h3>
            </Text>
          </LHInfo>

          <LHInfo>
            <Icon><img src={time} /></Icon>
            {isMobile ? <div className='m_text'>Completed Lessons</div> : <></>}
            <Text>
              <p>Completed Lessons</p>
              <h3>{learningVideos}</h3>
            </Text>
          </LHInfo>
          <LHInfo>
            <Icon><img src={play} /></Icon>
            {isMobile ? <div className='m_text'>Learning Time</div> : <></>}
            <Text>
              <p>Learning Time</p>
              {(learningHours >= 60)?<h3>{Math.round(learningHours/60)} <span>hr</span></h3> :<h3>{learningHours} <span>min</span></h3> }
            </Text>
          </LHInfo>

        </LHInfos>
        <LHLists>
          {learningHistoryWithPaging && learningHistoryWithPaging.content && learningHistoryWithPaging.content.map((item) => (
            <LHList>
              <Link to={`/courses/detail/${item.productId}/${item.chapterLectureId}`}>
                <ListIcon><img src={ready} /></ListIcon>
                <ListText>
                  <h3>{item.chapterLectureTitle}</h3>
                  <p>{item.title}</p>
                </ListText>
                <ListDate>
                  {item.endDate}
                </ListDate>
              </Link>
            </LHList>
          ))}

        </LHLists>
      </LHWrap>

      {/* 페이징 */}
      <Pagination
        t={t}
        isMobile={isMobile}
        currentPage={currentPage}
        setPage={setPage}
        btns={currentPageNumbers}
        maxPage={maxPage}
      />


    </LHDetailWrap>
  );
};

export default LearningHistoryDetail;

const LHDetailWrap = styled.div`
  max-width: 1300px;
  margin: 5.5rem auto;
  width:calc(100% - 40px);
  @media (max-width: 1024px) {
    margin-top:2rem;
    padding:15px 15px 20px;
  }
`

const LHWrap = styled.div`
  margin-top: 5.5rem;
`
const LHInfos = styled.div`
  display: flex;
  border-radius: 5px;
  background: #2b2e35;
  padding:4.5rem 0;
  align-items: center;
  justify-content:center;
  margin-bottom:3.2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap:10px;
    background: none;;
  }
`
const LHInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width:35rem;
  height: 6rem;;
  border-left:1px solid #44474e;
  gap:3.5rem;
  &:first-child{border-left:none;}

  @media (max-width: 1024px) {
    border:none;
    justify-content: flex-start;
    width: 100%;
    background:#2b2e35;
    border-radius: 5px;
    padding:0 20px;
    height:65px;
    .m_text{
      font-size:13px;
      color:#8e9199;
      flex:1;
    }
  }


`
const Icon = styled.div`
   @media (max-width: 1024px) {
    img{height:25px;}
   }
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;

    h3{
    color:#fff;
    font-size:4rem;
    line-height:1;
    font-weight: 500;
    span{font-size:2.4rem;}
  }
  p{
    color:#5f6268;
    line-height:1;
    font-size:1.6rem;
    font-weight: 500;
  }
  @media (max-width: 1024px) {
    justify-content: flex-end;
    p{display: none;}
    h3{
      span{font-size:13px;}
    }
   }
`
const LHLists = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:1rem;
`
const LHList = styled.div`
  width:100%;
  background-color: #1e2024;
  border-radius: 0.6rem;
  a{
    display: flex;
    align-items: center;
    height:8rem;
    width:100%;
    padding:2rem 2.5rem;;
    gap:2rem;
  }
  @media (max-width: 1024px) {
    padding:10px 15px;
   }
`
const ListIcon = styled.div`
  width:3rem;
  @media (max-width: 1024px) {
   img{width:25px;}
   }
`
const ListText = styled.div`
  flex:1;
  h3{
    font-size: 1.8rem;;
    color:#c9cbd1;;
    line-height:1;
    font-weight: 600;
  }
  p{
    color:#8b8f99;
    font-size:1.4rem;
    line-height:1;
    margin-top: 0.8rem;
  }
`
const ListDate = styled.div`
  width:10rem;
  justify-content: flex-end;
  font-size:1.6rem;
  color:#8b8f99;
  @media (max-width: 1024px) {
    display: none;
   }
`




const Head = styled.div`
  display:flex;
  justify-content: space-between;
  margin-bottom:2.5rem;
  align-items: flex-end;
  @media (max-width: 767px) {
     align-items: center;;

     &.learning-history-head{
      margin-bottom:0;
      > h3{
        margin-bottom: 0%;;
      }

     }
    }
`;
const LHHomeTitle = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  color: #c9cbd1;
  @media (max-width: 767px) {
    font-size: 3em;
    margin-bottom: 0.6em;
    color: #fff;
  }
`;
const MoreBtn = styled(Link)`
    color:#8b8f99;
    font-size:1.4rem;

    svg{
      fill:#8b8f99;
      margin-left:0.5rem;
    }
    &:hover{
      color:#ffb500;
      svg path{
        fill:#ffb500;
      }
    }
    @media (max-width: 767px) {
      font-size:0;
      img{
        width:11px;
      }
    }
`;
