import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/courses';
const size = 8;

/**
 * getAllList 모든 코스 목록
 * getCategoryList
 * getCourse 'name'과 일치하는 단일 코스
 * getMainVideoByCategory
 * getMyPageRecommend 마이페이지 Course 추천 목록
 * searchCourseList (library page)
 * getCourseRecommendList
 */
const coursesApi = {
  getAllList: async () => {
    let response = await asyncApi({ url, params: { size } });
    return response.data;
  },
  getCategoryList: async () => {
    let response = await asyncApi({
      url: `${url}/categories`,
    });
    return response.data;
  },
  getCourse: async ({ name = 0, page = 0 }) => {
    let response = await asyncApi({
      url,
      params: { name: name, page: page, size: 50 },
    });
    return response.data;
  },
  getMainVideoByCategory: async () => {
    let response = await asyncApi({
      url: `${url}?main`,
    });
    return response.data;
  },
  getMyPageRecommend: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url: `${url}?my-page&recommends`,
      params: { page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },

  //library page
  searchCourseList: async (page = 0, text, token = null) => {
    const searchUrl = text ? url + `?q=${text}` : url + '?q=';
    const header = token && { headers: { Authorization: `Bearer ${token}` } };

    const config = {
      url: searchUrl,
      params: { page: page, size: 12 },
      ...header,
    };
    const response = await asyncApi(config);
    return response.data;
  },
  getCourseRecommendList: async (page = 0, token) => {
    const header = token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const config = {
      // url: url + '?recommends',
      url: url + '?q=',
      params: { page, size: 12 },
      ...header,
    };
    const response = await asyncApi(config);

    return response.data;
  },
  // 230609 이벤트 페이지 겟 리스트
  getList230609: async (num) => {
    let response = await asyncApi({
      url: `/api/v1/courses?lectureView=${num}`,
    });
    return response.data;
  },
  // 코스페이지 LNB 가져오기
  getLnbMenu: async () => {
    let response = await asyncApi({
      url: `/api/v1/courses/leftVideoMenu`,
    });
    return response.data;
  },
  // 코스페이지 배너 가져오기
  getBanner: async () => {
    let response = await asyncApi({
      url: `/api/v1/courses/courseBanner`,
    });
    return response.data;
  },

  //한글날 이벤트 레벨별 추천 강좌
  getHangulDayEventRecommendedCourse: async (level) => {
    let response = await asyncApi({
      url: `/api/v1/courses/recommended`,
      params: {
        level: level
      }
    });
    return response.data;
  },
};

export default coursesApi;
