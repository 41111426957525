import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import ReactPlayer from 'react-player';
import videoThumbImg from 'assets/images/event/220714/video.png';

import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { addItem, deleteAllItem } from "container/PaymentPage/state/action";
import { useAxios } from 'common/hook/useAxiosHook';
import useLoginStatus from "common/hook/useLoginStatus";
import productApi from 'api/product';
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import ConfirmModal from "container/EventPage/ConfirmModal";
import userApi from 'api/user';
import contentsApi from 'api/contents';
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { isMobile } from 'react-device-detect';

const EventPage = () => {
	const { t } = useTranslation('event/20220714SpecialLecture');
	const language = localStorage.getItem('i18nextLng');

	const token = useSelector((state) => state.user.token);
	const isLogin = useLoginStatus();
	let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();
	const history = useHistory();
	let dispatch = useDispatch();
	const [vcProductId, setVCProductId] = React.useState(-1);

	// Video State
	const [playing, setPlaying] = useState(false);
	const [isPlay, setIsPlay] = useState(false);
	const onPLay = () => {
		setPlaying(true);
		setIsPlay(true);
	};

	// 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
	const [isMobileByWidth, setisMobile] = useState(false);
	const resizingHandler = () => {
		if (window.innerWidth <= 1024) {
			setisMobile(true);
		} else {
			setisMobile(false);
		}
	};
	useEffect(() => {
		if (window.innerWidth <= 1024) {
			setisMobile(true);
		}
		window.addEventListener("resize", resizingHandler);
		return () => {
			window.removeEventListener("resize", resizingHandler);
		};
	}, []);

	const openLink = (url, target) => {
		const userAgent = window.navigator.userAgent;
		if (userAgent === MOBILE_APP_USER_AGENT) {
			window?.WebInterface?.movetoAndroidBrowser(true, url);
		}else{
			window.open(url, target ? target : '_blank');
		}
	}

	//링크복사
	const doCopy = text => {

		// 흐름 1.
		if (!document.queryCommandSupported("copy")) {
		return toast.error("Unsupported browser.");
		}

		const API_HOST = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000' : process.env.REACT_APP_API_HOST;

		// 흐름 2.
		const textarea = document.createElement("textarea");
		textarea.value = API_HOST + text;
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.position = "fixed";

		// 흐름 3.
		document.body.appendChild(textarea);
		// focus() -> 사파리 브라우저 서포팅
		textarea.focus();
		// select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
		textarea.select();
		// 흐름 4.
		document.execCommand("copy");
		// 흐름 5.
		document.body.removeChild(textarea);
		alert("Link copied.");
		//toast.success("Link copied.");
	};
	
	const [
		{ loading: productLoading, data: productData },
		fetchProduct,
	] = useAxios(productApi.getVideoClassProduct, [], true);
	const [
		{ data: myVCData },
		fetchMyVCPaymentList
	] = useAxios(() => userApi.fetchMyPaymentListByItemType('VIDEOCLASS', token), [], true);

	useEffect(() => {
		if(productData){
			const name = (language) => {
				switch(language){
					case 'ko-KR':
						return productData.title;
					case 'en-US':
						return productData.titleEN;
					case 'zh-CN':
						return productData.titleCN;
					case 'ja-JP':
						return productData.titleJP;
					default:
						return productData.title;
				};
			}

			let videoItem = {
				id: productData.productId, //data.id,
				type: 'Video Class',
				name: name(language),
				price: productData.salesStoreWon,
				priceDollar: productData.salesStoreDollar,
				discountCost: productData.priceCostWon - productData.salesStoreWon,
				discountCostDollar: productData.priceCostDollar - productData.salesStoreDollar,
			};

			dispatch(deleteAllItem());
			dispatch(addItem(videoItem));
			history.push({
				pathname: `/payment/video-class`,
			})
		}
	}, [productData]);

	const productId = (index) => {
		if (process.env.REACT_APP_ENV === "production") {
			switch(index){
				case 0: return 1218;
				default: return 1218;
			}
		}else{
			switch(index){
				case 0: return 1190;
				default: return 1190;
			}
		}
	}

	useEffect(() => {
		if(myVCData && vcProductId >= 0){
			const orderList = myVCData.filter((data)=>data.productIdx === vcProductId);
			if(orderList.length > 0){
				//구매불가능
				handleModal(
					<ConfirmModal
						tMessage={t('doublePaymentModal.title')}
						tButton={t('doublePaymentModal.confirm')}
					/>
				)
				return;
			}else{
				//구매가능
				fetchProduct(vcProductId, token)
			}
		}
	},[myVCData, vcProductId])

	const clickApply = (index) => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}

		setVCProductId(productId(index));
		fetchMyVCPaymentList(token);
	}

	const [videoUrl, setVideoUrl] = useState(null);
	const [
		{ loading: videoUrlLoading, data: videoUrlData },
		fetchVideoByType,
	] = useAxios(contentsApi.getVideoByType, [], true);
	const videoProductId = process.env.REACT_APP_ENV === "production" ? 1194 : 1144;
	const videoLectureId = process.env.REACT_APP_ENV === "production" ? 4892 : 3670;
	const videoQuality = isMobile? '720P' : '1080P';

	useEffect(() => {
		videoUrlData && setVideoUrl(videoUrlData.url);
		videoUrlData && console.log("비디오url", videoUrlData.url)
		return () => {};
	}, [videoUrlData]);

	// video url 호출
	useEffect(() => {
		if(process.env.REACT_APP_ENV === "production"){
			if (videoQuality) {
			  fetchVideoByType(videoLectureId, videoQuality, token);
			}
			return () => {
			  setIsPlay(false);
			};
		}else{
			setVideoUrl('https://d13owoaeiymgg6.cloudfront.net/vod/cso_kr_reading/topik_cso_alive/HLS/topik_cso_alive.m3u8');
		}
	}, [fetchVideoByType, videoLectureId, token, videoQuality]);


	return (
		<Styled.EventWrap>
			<div className={language === 'ko-KR' ? 'ko' : ''}>
				<div className='visual'>
					<div className='inner'>
						<div className='blind'>
							<h2>{t('visual.title')}</h2>
							<p>{t('visual.desc')}</p>
							<p>{t('visual.desc2')}</p>
							<p>{t('visual.desc3')}</p>
						</div>
					</div>
				</div>

				<div className='detail'>
					<div className='inner'>
						<h2><span className='blind'>{t('detail.title')}</span></h2>
						<div className='desc'>
							<Trans t={t}>detail.desc</Trans>
							<h3>{t('detail.desc2')}</h3>
							<p>{t('detail.desc3')}</p>
						</div>
						<div className='preview'>
							<div className='titWrap'>
								<h4 className='tit'><span>{t('detail.preview')}</span></h4>
							</div>
							<div className='playerWrap'>
								<ReactPlayer
									controls
									onClick={onPLay}
									playing={playing}
									light= {videoThumbImg}
									// url='https://d13owoaeiymgg6.cloudfront.net/vod/cso_kr_reading/topik_cso_alive/HLS/topik_cso_alive.m3u8'
									url={videoUrl}
									width='100%'
									height='100%'
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='how'>
					<div className='inner'>
						<h2><span className='blind'>{t('how.title')}</span></h2>
						<ol>
							<li>
								<div>
									<span className='num'><em>1</em></span>
									<p>
										<Trans t={t}>how.step1.title</Trans>
									</p>
									{t('how.step1.desc')}
								</div>
							</li>
							<li>
								<div>
									<span className='num'><em>2</em></span>
									<p>
										<Trans t={t}>how.step2.title</Trans>
									</p>
									{t('how.step2.desc')}
								</div>
							</li>
							<li>
								<div>
									<span className='num'><em>3</em></span>
									<p>
										<Trans t={t}>how.step3.title</Trans>
									</p>
									{t('how.step3.desc')}
								</div>
							</li>
						</ol>
						<div className='btnWrap'>
							<a href="javascript:;" onClick={()=>clickApply(0)}>{t('how.button')}</a>
						</div>
					</div>
				</div>

				<div className='notification'>
					<div className='inner'>
						<h3>{t('notification.title')}</h3>
						<dl>
							<dt>{t('notification.group1.title')} (<a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/6718041279251"); return false;}}>{t('notification.click')}</a>)</dt>
							<dd>{t('notification.group1.desc1')}</dd>
							<dd>{t('notification.group1.desc2')}</dd>
							<dd>{t('notification.group1.desc3')}</dd>
							<dd>{t('notification.group1.desc4')}</dd>

							<dt>{t('notification.group2.title')}</dt>
							<dd>{t('notification.group2.desc1')}</dd>
							<dd>{t('notification.group2.desc2')}</dd>
							<dd>{t('notification.group2.desc3')}</dd>

							<dt>{t('notification.group3.title')}</dt>
							<dd>{t('notification.group3.desc1')}</dd>
							<dd>{t('notification.group3.desc2')}</dd>
							<dd>{t('notification.group3.desc3')}</dd>

							<dt>{t('notification.group4.title')}</dt>
							<dd>{t('notification.group4.desc1')} : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759778093203"); return false;}}>{t('notification.click')}</a></dd>
							<dd>{t('notification.group4.desc2')} : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759908099859"); return false;}}>{t('notification.click')}</a></dd>
							<dd>{t('notification.group4.desc3')} : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4420635446419"); return false;}}>{t('notification.click')}</a></dd>
						</dl>
					</div>
				</div>
			</div>
		</Styled.EventWrap>
  );
};

export default EventPage;