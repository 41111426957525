import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.style';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import productApi from 'api/product';
import contentsApi from 'api/contents';

import CourseViewAside from './Aside';
import CourseViewContents from './Contents';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import useNeedLogin from 'common/hook/useNeedLogin';

export const ViewContext = React.createContext();

const CourseViewPage = () => {
  console.log('CourseViewPage !!!!');

  useNeedLogin();

  const { path } = useRouteMatch();
  const { productId, lectureId } = useParams();
  const token = useSelector((state) => state.user.token);
  const playerRef = useRef(null);

  // API State
  const [
    { loading: productLoading, data: productData },
    fetchProduct,
  ] = useAxios(productApi.getProduct, [], true);
  const lectureState = useAxios(contentsApi.getLecture, [], true);

  useEffect(() => {
    productId && token && fetchProduct(productId, token);
    return () => {};
  }, [fetchProduct, productId, token]);

  const [product, setProduct] = useState();
  useEffect(() => productData && setProduct(productData), [productData]);

  // Video State
  const videoQualityState = useState(null);
  const isPlayState = useState(false);

  // 강의 정보 조회
  const [, fetchLecture] = lectureState;
  useEffect(() => {
    if (lectureId === '0') return;
    console.log('productId', productId, lectureId, token);
    fetchLecture(productId, lectureId, token);
    return () => {};
  }, [fetchLecture, lectureId, productId, token]);

  // Context Store
  const viewStore = {
    lectureState,
    videoQualityState,
    isPlayState,
    playerRef,
  };

  return (
    <ViewContext.Provider value={viewStore}>
      <LoadingOverlay isLoading={productLoading} />
      <Styled.Wrapper>
        <Styled.Container>
          <Route
            exact
            path={path}
            render={() => <CourseViewContents ref={playerRef} />}
          />
        </Styled.Container>

        <Styled.Aside>
          <CourseViewAside product={product} />
        </Styled.Aside>
      </Styled.Wrapper>
    </ViewContext.Provider>
  );
};

export default CourseViewPage;
