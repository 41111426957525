import React, { useCallback, useEffect, useState } from 'react';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledModalForm from 'component/form/ModalForm.style';
import Styled from './styled/Login.style';
import useBlockLoginUser from 'common/hook/useBlockLoginUser';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import CreateAccount from 'component/user/CreateAccount';
import FindPassword from 'component/user/FindPassword';
import { actions, Types } from 'component/user/state';
import {
  FACEBOOK_AUTH_URL,
  GOOGLE_AUTH_URL,
  UserStatus,
  WECHAT_AUTH_URL,
} from 'common/constant';
import useFetchInfo from 'common/hook/useFetchInfo';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isMobile, getUA } from 'react-device-detect';
import { MOBILE_APP_USER_AGENT } from 'common/constant';

import { loginAddGa } from 'component/user/api/userApi';

export default function Login() {
  useBlockLoginUser();

  const { t } = useTranslation('loginModal');
  let { handleModal } = React.useContext(ModalContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fbJWTToken, setFbJWTToken] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const { data: UserData, token } = useSelector((state) => state.user);
  const loginStatus = useSelector((state) => state.user.status);

  const changeCreatAccountModal = (e) => {
    e.preventDefault();
    handleModal(<CreateAccount />);
  };

  const changeFindPasswordModal = (e) => {
    e.preventDefault();
    handleModal(<FindPassword />, false);
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!username || !password) {
        toast.error(t('invalidAlert'));
        return false;
      }
      dispatch(actions.fetchLogin(username, password));
    },
    [username, password]
  );

  const changeCreateSNSModal = useCallback(() => {
    // handleModal(<CreateSNSAccount token={fbJWTToken} isTerms={false} />, false);
    history.push(`/auth/redirect?token=${fbJWTToken}&isTerms=false`);
  }, [fbJWTToken]);

  useEffect(() => {
    window['handleFBAccessToken'] = (data) => {
      const response = JSON.parse(data);
      const { result, token, msg, id, email } = response;
      setFbJWTToken(token);

      //result: success | cancel | error
      if (result === 'success') {
        if (!token || !email) return;
        dispatch(actions.fetchLoginFacebook(token, email, id));
      } else if (result === 'error') {
        toast.error(msg ? msg : 'Something went wrong');
      }
    };
  }, []);

  useEffect(() => {
    const isRest = UserData?.isRest;
    return () => {
      if (isRest) {
        history.push('/dormant-account');
      }
    };
  }, [UserData]);

  const { isFetching: isFetchingLogin } = useFetchInfo(Types.FetchLogin);
  const { isFetching: isFetchingUser } = useFetchInfo(Types.FetchUser);

  useEffect(() => {
    loginStatus === UserStatus.Login && handleModal();
    if (loginStatus === UserStatus.LoginFailed && !isMobile) {
      toast.error(t('invalidAlert'));
    } else if (loginStatus === UserStatus.LoginFailed && isMobile) {
      toast.error('Something went wrong');
    } else if (loginStatus === UserStatus.NotRegistered) {
      changeCreateSNSModal();
    }

    return () => {
      if (
        loginStatus === UserStatus.LoginFailed ||
        loginStatus === UserStatus.NotRegistered
      )
        dispatch(actions.setValue('status', ''));

      if (loginStatus === UserStatus.Login) {
        const encodeStr = loginAddGa(username)
          .then((data) => {
            console.log('data', data);

            if (window.dataLayer) {
              window.dataLayer.push({
                user_id: data.encodeStr,
              });
            } else {
              console.log('window.dataLayer', window.dataLayer);
            }
          })
          .catch((e) => {
            console.log('error', e);
          });
      }
    };
  }, [loginStatus]);

  return (
    <>
      <StyledModal.H2>{t('title')}</StyledModal.H2>
      <StyledModalForm.FormWrapper onSubmit={onSubmit}>
        <Styled.IdIcon>
          <Styled.Input
            type='text'
            name='username'
            autoComplete='username'
            title={t('email')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Styled.IdIcon>

        <Styled.PwIcon>
          <Styled.Input
            type='password'
            name='password'
            autoComplete='current-password'
            title={t('pw')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Styled.PwIcon>

        <Styled.FindPwDiv>
          <Link to='#none' onClick={changeFindPasswordModal}>
            {t('findPw')}
          </Link>
        </Styled.FindPwDiv>

        <StyledModalForm.Button type='submit'>
          {t('login')}
        </StyledModalForm.Button>

        <Styled.CreateAccountDiv>
          <Link to='#none' onClick={changeCreatAccountModal}>
            {t('createLink')}
          </Link>
        </Styled.CreateAccountDiv>

        <Styled.SocialLogin dataOr={t('or')}>
          <Styled.GoogleButton href={GOOGLE_AUTH_URL}>
            {t('google')}
          </Styled.GoogleButton>
          <Styled.FacebookButton href={FACEBOOK_AUTH_URL}>
            {t('facebook')}
          </Styled.FacebookButton>
          {(!isMobile || getUA === MOBILE_APP_USER_AGENT) && (
            <Styled.WechatButton href={WECHAT_AUTH_URL}>
              {t('wechat')}
            </Styled.WechatButton>
          )}
        </Styled.SocialLogin>

        <LoadingOverlay isLoading={isFetchingLogin || isFetchingUser} />
      </StyledModalForm.FormWrapper>
    </>
  );
}
