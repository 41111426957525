import React from 'react';
import Styled from './index.style';
import SiteMap from './SiteMap';
import SiteMapAfterLogin from './SiteMapAfterLogin';
import Img from 'component/atoms/Img';

import logoImg from 'assets/images/logo02.png';
import useLoginStatus from 'common/hook/useLoginStatus';
//import { SiteMapData } from 'common/constant';
//import { Link } from 'react-router-dom';
//import { MdKeyboardArrowRight } from 'react-icons/md';
//import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import Coupon from "./Coupon";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';


export default function Footer() {
  const isLogin = useLoginStatus();
  const location = useLocation();

  const { t } = useTranslation('footer');
  const userData = useSelector((state) => state.user.data);

  //무료체험 이후 FREE 상태 쿠폰 보여주기
  const couponBanner = () => {
    if (isLogin) {
      if(userData.orderPayInfo.length !== 0 && userData.serviceLevel === "FREE") {
        return (
          <Coupon />
        );
      };
    };
  };

  if (location.pathname.startsWith("/event/korean")) return (<></>);
  if (location.pathname.startsWith("/MtCurriculum")) return (<></>);

  return (
    <Styled.Wrapper>
      {isLogin ? <SiteMapAfterLogin /> : <SiteMap />}

      <Styled.Container>
        {/* {isLogin && (
          <Styled.TermsContainer>
            <Styled.TermsLink to={SiteMapData.privacy}>
              Privacy
            </Styled.TermsLink>
            <Styled.TermsLink to={SiteMapData.terms}>Terms</Styled.TermsLink>
          </Styled.TermsContainer>
        )} */}

        {/* <Styled.Logo>
          <Img src={logoImg} alt='Master Topik' />
        </Styled.Logo> */}

        <Styled.copy>{t('Copyright')}</Styled.copy>
        <Styled.Text>
          {t('title')}
          <span>|</span> {t('CEO')}
          <span>|</span> {t('business reporting number')}
          <span>|</span> {t('Registration Number')}
          <span>|</span> {t('Address')}
          <br/>
          {/* <span>|</span> */}
           {t('Email')}
          {/* <br /> */}
        </Styled.Text>
      </Styled.Container>

      {/* 20221209 롤링배너 추가로 재구독 팝업 삭제*/}
      {/* {couponBanner()} */}


    </Styled.Wrapper>
  );
}
