import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from 'component/atoms/PageHeader';
import DonutChart from './DonutChart';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import time from 'assets/images/mycurriculum_new/icon_time.png';
import play from 'assets/images/mycurriculum_new/icon_play.png';
import check from 'assets/images/mycurriculum_new/icon_check.png';
import complete from 'assets/images/mycurriculum_new/icon_complete.png';
import more from 'assets/images/mycurriculum_new/arrow2.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import contentsApi from 'api/contents';
import { useSelector } from 'react-redux';
import moment from 'moment';

const date = new Date(1701590721000);
const dateFormat = 'YYYY. MM. DD';
const formattedDate = moment(date).format(dateFormat);

const LearningHistory = ({ data, type }) => {
  const history = useHistory();
  const [isMobile, setisMobile] = useState(false);
  const [filteredList, setFilteredList] = useState();
  const [learningHours, setLearningHours] = useState(0);
  const [learningCourses, setLearningCourses] = useState(0);
  const [learningVideos, setLearningVideos] = useState(0);

  const token = useSelector((state) => state.user.token);

  const [{ data: learningHistoryData }, fetchLearningHistory] = useAxios(
    contentsApi.getHistoryList,
    [],
    true
  );

  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  useEffect(() => {
    fetchLearningHistory(token);
  }, [fetchLearningHistory]);

  useEffect(() => {
    if (learningHistoryData) {
      let courseCountSet = new Set;
      let sumOfTime = 0;

      const list = learningHistoryData.filter((item) => item.completed === true);
      list.forEach((item) => {
        courseCountSet.add(item.productId);
        sumOfTime += item.minute;
      });

      setLearningCourses(courseCountSet.size);
      setLearningVideos(list.length);
      setLearningHours(sumOfTime);

      setFilteredList(list);
    }
  }, [learningHistoryData]);

  return (
    <>
      {!type && <PageHeader title={"Learning History"} type={"mycurriculum"} />}
      <LHWrap className={`${type == 'Home' && 'learning-history-Wrap'}`}>
        {type == 'Home' && <Head className={`${type == 'Home' && 'learning-history-head'}`}>
          <LHHomeTitle>Learning History</LHHomeTitle>
          <MoreBtn onClick={() => history.push('/learningHistory')}>
            MORE
            {isMobile ? <img src={more} />
              :
              <svg
                width="10px" height="10px">
                <path fill-rule="evenodd" fill="#8b8f99"
                  d="M9.1000,5.1000 L5.1000,5.1000 L5.1000,9.1000 L3.1000,9.1000 L3.1000,5.1000 L-0.000,5.1000 L-0.000,3.1000 L3.1000,3.1000 L3.1000,-0.000 L5.1000,-0.000 L5.1000,3.1000 L9.1000,3.1000 L9.1000,5.1000 Z" />
              </svg>
            }
          </MoreBtn>
        </Head>
        }
        <LHInfos>
          <LHInfo>
            <Icon><img src={time} /></Icon>
            {isMobile ? <div className='m_text'>Completed Courses</div> : <></>}
            <Text>
              <h3>{isMobile? learningCourses : learningHours >=60 ? Math.round(learningHours/60) : learningHours}</h3>
              <p>{learningHours >= 60 ? "Hours" : "Mins"}</p>
            </Text>
          </LHInfo>
          <LHInfo>
            <Icon><img src={play} /></Icon>
            {isMobile ? <div className='m_text'>Completed Lessons</div> : <></>}
            <Text>
              <h3>{isMobile? learningVideos : learningCourses}</h3>
              <p>Courses</p>
            </Text>
          </LHInfo>
          <LHInfo>
            <Icon><img src={check} /></Icon>
            {isMobile ? <div className='m_text'>Learning Time</div> : <></>}
            <Text>
              <h3>{isMobile ? learningHours >= 60? Math.round(learningHours/60) : learningHours : learningVideos}{isMobile ?  learningHours >= 60?<span>hr</span>:<span>m</span> : ""}</h3>
              <p>Videos</p>
            </Text>
          </LHInfo>
        </LHInfos>
        <LHLists>
          {filteredList && filteredList.map((item, index) => {
            if (index < 2) {
              return (
                <LHList>
                  <ListIcon><img src={complete} /></ListIcon>
                  <ListText>
                    <h3>{item.chapterLectureTitle}</h3>
                    <p>{item.title}</p>
                  </ListText>
                  <ListDate>
                  {item.endDate}
                  </ListDate>
                </LHList>);
            }
          })}
          {/* <LHList>
              <ListIcon><img src={complete}/></ListIcon>
              <ListText>
                  <h3>3. 가장 최근 수강한 강의 명</h3>
                  <p>TOPIK Reading 3-4 (강좌명)</p>
              </ListText>
              <ListDate>
                2023.11.08
              </ListDate>

            </LHList>
            <LHList>
              <ListIcon><img src={complete}/></ListIcon>
              <ListText>
                  <h3>3. 가장 최근 수강한 강의 명</h3>
                  <p>TOPIK Reading 3-4 (강좌명)</p>
              </ListText>
              <ListDate>
                2023.11.08
              </ListDate>

            </LHList> */}
        </LHLists>
      </LHWrap>
    </>
  );
};

export default LearningHistory;

const LHWrap = styled.div`
  background:#1e2024;
  border-radius: 5px;
  padding:4rem 3rem;
  margin-top: 4rem;

  &.learning-history-Wrap{
    max-width: 1300px;
    margin:3rem auto;
    width:calc(100% - 40px);
  }
  @media (max-width: 1024px) {
    margin-top:2rem;
    padding:15px 15px 20px;
  }
`
const LHInfos = styled.div`
  display: flex;
  border-radius: 5px;
  background: #2b2e35;
  padding:2rem 0;
  align-items: center;
  justify-content:center;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap:10px;
    background: none;;
  }
`
const LHInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width:35rem;
  height: 6rem;;
  border-left:1px solid #44474e;
  gap:3.5rem;
  &:first-child{border-left:none;}

  @media (max-width: 1024px) {
    border:none;
    justify-content: flex-start;
    width: 100%;
    background:#2b2e35;
    border-radius: 5px;
    padding:0 20px;
    height:65px;
    .m_text{
      font-size:13px;
      color:#8e9199;
      flex:1;
    }
  }


`
const Icon = styled.div`
   @media (max-width: 1024px) {
    img{height:25px;}
   }
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;

    h3{
    color:#fff;
    font-size:4rem;
    line-height:1;
    font-weight: 500;
  }
  p{
    color:#5f6268;
    line-height:1;
    font-size:1.6rem;
    font-weight: 500;
  }
  @media (max-width: 1024px) {
    justify-content: flex-end;
    p{display: none;}
    h3{
      span{font-size:13px;}
    }
   }
`
const LHLists = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const LHList = styled.div`
  display: flex;
  align-items: center;
  height:8rem;
  width:100%;
  border-bottom:1px solid #25272d;
  padding:1.5rem 4rem;
  gap:2rem;
  @media (max-width: 1024px) {
    padding:10px 15px;
   }
`
const ListIcon = styled.div`
  width:3rem;
  @media (max-width: 1024px) {
   img{width:25px;}
   }
`
const ListText = styled.div`
  flex:1;
  h3{
    font-size: 2rem;
    color:#ffffff;;
    line-height:1;
  }
  p{
    color:#8b8f99;
    font-size:1.4rem;
    line-height:1;
    margin-top: 0.8rem;
  }
`
const ListDate = styled.div`
  width:10rem;
  justify-content: flex-end;
  font-size:1.6rem;
  color:#8b8f99;
  @media (max-width: 1024px) {
    display: none;
   }
`




const Head = styled.div`
  display:flex;
  justify-content: space-between;
  margin-bottom:2.5rem;
  align-items: flex-end;
  @media (max-width: 767px) {
     align-items: center;;

     &.learning-history-head{
      margin-bottom:0;
      > h3{
        margin-bottom: 0%;;
      }

     }
    }
`;
const LHHomeTitle = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  color: #c9cbd1;
  @media (max-width: 767px) {
    font-size: 3em;
    margin-bottom: 0.6em;
    color: #fff;
  }
`;
const MoreBtn = styled(Link)`
    color:#8b8f99;
    font-size:1.4rem;

    svg{
      fill:#8b8f99;
      margin-left:0.5rem;
    }
    &:hover{
      color:#ffb500;
      svg path{
        fill:#ffb500;
      }
    }
    @media (max-width: 767px) {
      font-size:0;
      img{
        width:11px;
      }
    }
`;
